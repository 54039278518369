import PropTypes from "prop-types";
import "./ReplyMessages.css";
import { useState } from "react";
import ReplySection from "./ReplySection";

const ReplyMessages = ({ messages, orderId, hasChatClosed }) => {
  const messageLength = messages.length;
  const [showMessages, setShowMessages] = useState(true);

  return (
    <div className="reply-messages">
      {messageLength > 0 ? (
        <span
          className="show-messages"
          onClick={() => setShowMessages(!showMessages)}
        >
          {showMessages ? "Hide" : "Show"} Messages
        </span>
      ) : (
        <></>
      )}

      {showMessages ? (
        <>
          {messages.map((data, index) => (
            <div
              key={index}
              className={`message ${
                data.sentBy === "staff" ? "staff" : "guest"
              }`}
            >
              <div className="message-content">{data.message}</div>
              {/* <div className="message-sender"> Sent by: {data.sentBy}</div> */}
            </div>
          ))}
        </>
      ) : (
        <></>
      )}

      {showMessages && !hasChatClosed && messageLength ? (
        <div style={{ marginTop: "20px" }}>
          <ReplySection orderId={orderId} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

ReplyMessages.propTypes = {
  messages: PropTypes.array.isRequired,
  orderId: PropTypes.string.isRequired,
  hasMessageReadByGuest: PropTypes.bool.isRequired,
  hasChatClosed: PropTypes.bool.isRequired,
};

export default ReplyMessages;
