import { useState, useEffect, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";

import HomeNavigators from "./homeNavigators";
import {
  getFirstLogin,
  getUser,
  logoutUser,
  setFirstLogin,
} from "../../utils/sessionManager";
import { useTranslation } from "react-i18next";
import { getValue } from "../../utils/object";
import Logout from "../../assets/images/icon/log-in-04.svg";
import { DynamicRoutes } from "../../constants/routes";
import OfferModal from "../../components/Modal/OfferModal";
import { listModule } from "../../services/manageModuleService";
import useAnalyticsEventTracker from "../../hooks/analytics.hooks";
import { appService } from "../../services/appService";
import InboxTooltip from "../ReplySection/InboxToolTip";
import { checkUnreadChatMessage } from "../../services/chatService";

const HOME_TAB = "#home";
const STATUS_TAB = "#my_status";
const INBOX_TAB = "#inbox";

const currentUser = getUser();

export default function Main() {
  const location = useLocation();
  const gaEventTracker = useAnalyticsEventTracker();

  const [activeMenu, setActiveMenu] = useState(
    location.hash === STATUS_TAB ? "Status" : "Home"
  );
  const [showOfferModal, setShowOfferModal] = useState(false);
  const [module, setModule] = useState([]);
  const [unreadChatMessage, setUnreadChatMessage] = useState(0);

  const tenantId = appService.getTenantId();

  const { t } = useTranslation();

  const gTranslation = t("global");

  const handleMenuClick = (menuItem) => {
    setActiveMenu(menuItem);
  };

  const handleLogoutClick = () => {
    logoutUser();
    window.location.replace(DynamicRoutes.Login);
  };


  const fetchUnreadChatMessage = useCallback(async () => {
    try {
      const response = await checkUnreadChatMessage({
        guestId: getValue(currentUser, "_id"),
      });
      setUnreadChatMessage(response?.count)
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    fetchUnreadChatMessage(); 

    const intervalId = setInterval(() => {
      fetchUnreadChatMessage();
    }, 20000); // 20 seconds

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [fetchUnreadChatMessage]);

  useEffect(() => {
    listModule(setModule);
  }, []);



  useEffect(() => {
    const isFirstLogin = getFirstLogin();
    if (isFirstLogin === "Yes") {
      setFirstLogin("No");
    }
  }, []);

  useEffect(() => {
    const getModuleStatus = (modules, moduleName) =>
      modules
        ?.filter((item) => item.modulesName === moduleName)
        .map((item) => item.isActive);

    const marketplace = getModuleStatus(module, "marketplace");

    if (marketplace && marketplace[0]) {
      const trackInterval = setTimeout(() => {
        setShowOfferModal(true);
      }, 2000);

      return () => clearTimeout(trackInterval);
    }
  }, [module]);

  useEffect(() => {
    if (![HOME_TAB, STATUS_TAB].includes(window.location.hash)) {
      window.location.href += activeMenu === "Status" ? STATUS_TAB : HOME_TAB;
    }
  }, [activeMenu]);

  const handleCloseOfferModal = () => {
    setShowOfferModal(false);
  };

  const onOfferModalClickClick = () => {
    gaEventTracker({
      eventName: "Winter_Offer_PopUp_Click",
      category: "User",
      action: `Winter Offer PopUp Click`,
      label: `Hotel: ${tenantId}`,
    });
  };

  return (
    <>
      <OfferModal
        isOpen={showOfferModal}
        closeModal={handleCloseOfferModal}
        onOfferModalClickClick={onOfferModalClickClick}
        tenantId={tenantId}
      />
      <section
        className="mtabs_items_area"
        style={{
          fontFamily: "Inter",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3 col-md-10 offset-md-1">
              <div id="mtabs">
                <div className="home_tab mb-3 mb-md-4">
                  <ul className="d-flex align-items-center ">
                    <li
                      onClick={() => handleMenuClick("Home")}
                      className={`${
                        activeMenu === "Home"
                          ? "ui-tabs-active ui-state-active"
                          : ""
                      }`}
                    >
                      <Link to={HOME_TAB}>
                        {getValue(gTranslation, "home")}
                      </Link>
                    </li>
                    <li
                      onClick={() => handleMenuClick("Status")}
                      className={`${
                        activeMenu === "Status"
                          ? "ui-tabs-active ui-state-active"
                          : ""
                      }`}
                    >
                      <Link to={STATUS_TAB}>
                        {getValue(gTranslation, "status")}
                      </Link>
                    </li>
                  </ul>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "30px",
                    }}
                  >
                    <Link to={DynamicRoutes.UnreadMessageList}>
                      <InboxTooltip hasUnreadChats={unreadChatMessage} />
                    </Link>
                    <div onClick={handleLogoutClick}>
                      <img src={Logout} alt="logout" />
                      <span>logout</span>
                    </div>
                  </div>
                </div>
                <div className="all_tabs_items">
                  <div>
                    <div className="row px-2">
                      <HomeNavigators activeMenu={activeMenu} module={module} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
