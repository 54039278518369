import { useContext, useRef, useState } from "react";
import "./ReplySection.css"; // Import custom CSS
import { useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { getValue } from "../../utils/object";
import API from "../../constants/api";
import { submitReply } from "../../services/chatService";
import { ProfileContext } from "../../contexts/ProfileContext";

const ReplySection = ({ orderId }) => {
  const { requestUpdated, setRequestUpdated } = useContext(ProfileContext);

  const replyRef = useRef(null);
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [replyMessage, setReplyMessage] = useState("");

  const userDetails = JSON.parse(localStorage.getItem("avendi_user") || "{}");

  const handleReplyChange = (e) => {
    setReplyMessage(e.target.value);
  };

  const handleReplySubmit = async () => {
    try {
      await submitReply({
        orderId: orderId,
        payload: {
          message: replyMessage,
          sentBy: "guest",
          staffEmail: getValue(userDetails, "email"),
        },
      });
      setReplyMessage("");
      setShowReplyForm(false);
      setRequestUpdated(!requestUpdated);
    } catch (error) {
      console.log("Error in sending reply", error);
    }
  };

  const handleClickOutside = (event) => {
    if (replyRef.current && !replyRef.current.contains(event.target)) {
      setShowReplyForm(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={replyRef}>
      <div className="reply-button-container">
        <button
          className="reply-button"
          onClick={() => setShowReplyForm(!showReplyForm)}
        >
          Reply
        </button>
      </div>
      {showReplyForm && (
        <div className="reply-section">
          <textarea
            className="reply-input"
            placeholder="Write your reply..."
            value={replyMessage}
            onChange={handleReplyChange}
          />
          <button className="submit-button" onClick={handleReplySubmit}>
            Submit
          </button>
        </div>
      )}
    </div>
  );
};

ReplySection.propTypes = {
  orderId: PropTypes.string.isRequired,
};

export default ReplySection;
