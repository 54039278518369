import axios from "axios";
import API from "../constants/api";

export const getRequestDetails = async ({ requestId, tenantId, accessToken }) => {
  const res = await axios({
    url: `${API.GET_REQUEST_DETAILS}/${requestId}`,
    method: "get",
    headers: {
        authorization: `Bearer ${accessToken}`,
      username: tenantId,
    },
  });
  return res.data;
};
