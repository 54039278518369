import axios from "axios";
import API from "../constants/api";
import { appService } from "./appService";
import { getAccessToken } from "../utils/sessionManager";

const access_token = getAccessToken();

export const submitReply = async ({ orderId, payload }) => {
  const res = await axios.put(`${API.POST_REPLY}/${orderId}`, payload, {
    headers: {
      authorization: `Bearer ${access_token}`,
      username: appService.getTenantId(),
    },
  });

  return res;
};

export const checkUnreadChatMessage = async ({guestId}) => {
  const res = await axios({
    url: `${API.CHECK_UNREAD_CHAT_MESSAGE}/${guestId}/check-unread-chat-messages`,
    method: "get",
    headers: {
      authorization: `Bearer ${access_token}`,
      username: appService.getTenantId(),
    },
  });
  return res.data;
};

export const getUnreadChatMessageList = async ({guestId}) => {
  const res = await axios({
    url: `${API.CHECK_UNREAD_CHAT_MESSAGE}/${guestId}/unread-chat-message-list`,
    method: "get",
    headers: {
      authorization: `Bearer ${access_token}`,
      username: appService.getTenantId(),
    },
  });
  return res.data;
};

export const markAsReadChatMessage = async ({requestId}) => {
  const res = await axios({
    url: `${API.MARK_AS_READ_CHAT_MESSAGE}/${requestId}`,
    method: "put",
    headers: {
      authorization: `Bearer ${access_token}`,
      username: appService.getTenantId(),
    },
  });
  return res.data;
};
