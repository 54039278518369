import PropTypes from "prop-types";
import { IoChatboxEllipsesOutline } from "react-icons/io5";

const InboxTooltip = ({ hasUnreadChats }) => {
  return (
    <div style={{ position: "relative", display: "inline-block", cursor: "pointer" }}>
      <IoChatboxEllipsesOutline
        size={24}
        style={{
          cursor: "pointer",
          animation: hasUnreadChats
            ? "chat-animation 1s ease-in-out infinite"
            : "none",
        }}
        color={"#000"}
      />
    </div>
  );
};

InboxTooltip.propTypes = {
  hasUnreadChats: PropTypes.bool,
};

export default InboxTooltip;
